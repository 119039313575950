.supporting-files-container {
  & { height: 100%; width: 100%; }
  
  .supporting-files-header{ 
    & { height: 10%; padding: 0 1rem; align-items: center; justify-content: space-between; background-color: #1359E4; display: flex; gap: 10px; }
    // &>span { text-transform: capitalize; font-size: 22px; color: #FFFFFF; }
    // .add-new-file-btn {
    //   & { margin-left: auto; background: #FFFFFF; color: #1359E4; }
    // }
    .header-title { 
      & { display: flex; align-items: flex-start; justify-content: center; flex-direction: column; }
      .course-name { font-weight: 400; color: #FFF; }
    }
    .image-title{ 
      & { display: flex; gap: 10px; align-items: center; }
      .back-arrow { margin: 0 1rem; }
    }
    h1 { font-size: 24px; color: #FFFFFF; }
    img { height: 48px; width: 48px; border-radius: 4px; object-fit: cover; }

    .header-cta {
      & { border: 2px solid #FFF; }
    }
  }
    
  .supporting-files {
    & { padding: 1rem; height: 90%; display: flex; align-items: flex-start; justify-content: flex-start; column-gap: 1rem;  flex-wrap: wrap; overflow-x: scroll; }
    .supporting-file {
      & { height: 250px; width: 400px; background-color: transparent; box-shadow: 0 0 7px #aaaaaa53; border-radius: 8px; }
      .file-preview {
        & { width: 100%; height: 200px; background: #e0e0e0; border-radius: 8px 8px 0 0; display: flex; align-items: center; justify-content: center;}
        img {
          & { height: 100%; width: 100%; border-radius: 8px 8px 0 0; object-fit: cover; }
        }
      }
      .file-name {
        & { font-weight: 600; padding: 8px; margin: 0; }
      }
      .file-title{
        & {display: flex; justify-content: space-between; font-weight: bold; height: 50px; align-items: center; font-size: 16px; color: #000000; padding: 8px 16px;}
        .edit-icons { 
          & { display: flex;  gap: 10px; align-items: center; }
          .type {
            & { display: flex; align-items: center; justify-content: center; font-size: 12px; background: #d8d8d8; border-radius: 3px; height: 16px; padding: 0 5px; }
          }
        }
      }
    }
    .no-files {
      & { width: 100%; display: flex; align-items: center; justify-content: center; }
    }
  }
}