.episodes-container {
  & { height: 100%; }
}
.episodes-published {
  & { height: calc(100% - 136px); width: 100%;  padding: 1rem; }
  .drag-drop-episodes {
    & { padding-right: 1rem; height: 100%; width: 100%; overflow-x: scroll; }
    .episode-row {
      & { background: #FFF; box-shadow: 0 0 10px #cecece; border: 1px solid #eee; border-radius: 6px; margin-bottom: 1rem; position: relative; height: 230px; width: 100%; display: flex; align-items: center; justify-content: space-between; gap: 16px; }
      .card-index-drag {
        & { display: flex; align-items: center; justify-content: center; gap: 10px; padding: 0 1rem;  }
      }

      .post-status{
        & { height: 100%; width: 5px; position: absolute; top: 0; left: 0; }
      }
    
      .post-status-draft{
        background-color: #C6C6C6;
        border-radius: 40px 0 0 40px;
      }
      .post-status-published{
        background-color: #24BC9C;
        color: #FFF;
        border-radius: 40px 0 0 40px;
      }
      .draft{
        background-color: #C6C6C6;
        border-radius: 40px 0 0 40px;
      }
      .published{
        background-color: #24BC9C;
        border-radius: 40px 0 0 40px;
        color: #FFF;
      }

      .devo-img-container {
        & { height: 168px; min-width: 300px; width: 300px; max-height: 168px; max-width: 100%; margin-bottom: 16px;}
        .devotional-img {border-radius: 4px; object-fit: cover; height: 100%; width: 100%; }
      }
      .placeholder-img {
        & { border-radius: 4px; padding: 10px 0; min-width: 300px; width: 300px; max-height: 165px; min-height: 165px;  border-radius: 10px; border: 2px dashed #656565; display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; }
        img { border-radius: 4px; object-fit: contain; height: 100px; max-width: 70%; width: 70%; }
        .placeholder-text { height: 30%; text-align: center; width: 90%; }
        .episode-number {
          & { position: absolute; top: 30px; left: 40px; }
        }
        .episode-upload-thumbnail-btn {
          & { position: absolute; bottom: 165px; right: 40px; }
        }
      }
      .episode-upload-btn { width: 200px; }

      .episode-details {
        & { display: flex; align-items: center; flex: auto; gap: 10px; }
        .status-edit-container {
          & { display: flex; align-items: center; justify-content: center; gap: 1rem; }
        }
        .episode-title-desc {
          & { flex: auto; }
        }
        .editable-row{
          & { min-width: 300px; width: 300px; font-size: 14px; display: flex; flex-direction: column; justify-content: space-between; } 
          .status-row{align-items: center; display: flex; gap: 10px;
            .author-tags{
              .author-tag{ cursor: pointer; padding: 10px 20px; border-radius: 24px;}
            }
          }
        }
        .episode-action-btns {
          & { margin-top: 1rem; display: flex; align-items: center; justify-content: center; gap: 1rem; }
          .episode-action-btn {
            & { background: #1359E4; color: #FFF; }
          }
        }

      }
    }
  }
}

.episodes-draft {
  & { height: calc(100% - 136px); width: 100%; overflow-x: scroll; padding: 1rem; display: flex; flex-wrap: wrap; gap: 2rem; }
  .drag-drop-episodes {
    & { height: 100%; width: 100%; }
    .episode-row {
      & { height: 350px; width: 100%; }

    }
  }
  .blog-card{ padding: 16px 16px 16px 16px; width: 32%; max-width: 400px; min-width: 300px;  background: #FFFFFF; border-radius: 8px; position: relative; box-shadow: 0px 3px 10px #0000000A !important;
    p{
      margin: 0 0 8px 0;
      padding: 0;
    }

    .post-status{
      height: 8px;
      margin-bottom: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  
    .post-status-draft{
      background-color: #C6C6C6;
  
    }
    .post-status-published{
      background-color: #24BC9C;
      color: #FFF;
    }
    .draft{
      background-color: #C6C6C6;
  
    }
    .published{
      background-color: #24BC9C;
      color: #FFF;
    }
    
    .devo-img-container {
      & { height: 168px; width: 100%; max-height: 168px; max-width: 100%; margin-bottom: 16px;}
      .devotional-img {border-radius: 4px; object-fit: cover; height: 100%; width: 100%; }
    }
    .placeholder-img {
      & { border-radius: 4px; padding: 10px 0; max-height: 165px; min-height: 165px;  border-radius: 10px; border: 2px dashed #656565; display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; }
      img { border-radius: 4px; object-fit: contain; height: 125px; max-width: 70%; width: 70%; }
      .placeholder-text { height: 30%; text-align: center; width: 90%; }
      .episode-number {
        & { position: absolute; top: 30px; left: 40px; }
      }
      .episode-upload-thumbnail-btn {
        & { position: absolute; bottom: 165px; right: 40px; }
      }
    }

    .devo-title{
      
      min-width: 100%;
      .engaments-row{ display: flex; justify-content: space-between; }
      .editable-row{
        font-size: 14px; display: flex; justify-content: space-between;
        .status-row{align-items: center; display: flex; gap: 10px;
          .author-tags{
            .author-tag{ cursor: pointer; padding: 10px 20px; border-radius: 24px;}
          }
        }
      }
      .episode-upload-btn {
        & { background: #1359E4; color: #FFF; width: 100%; align-self: flex-end;  margin-top: 1rem; }
      }

     .devo-title-text { margin-top: 1rem; font-weight: 600; }
     .devo-title-date {
      font-size: 14px; font-weight: bold;
        span{ font-weight: normal;}
      } 

      .episodes-row {
        & { display: flex; align-items: center; justify-content: space-between; }
        .episode-list {
          & { display: flex; align-items: center; position: relative; }
          .episode-template {
            & { width: 180px; display: flex; align-items: center; position: relative; }
            .episode-thumb {
              & { display: flex; align-items: center; justify-content: center; position: absolute; height: 32px; width: 32px; background: #E9EAEE; border-radius: 5px; cursor: pointer; box-shadow: 0px 2px 5px #00000029; }
            }
            .episode-thumb-1 {
              & { left: 0px; }
            }
            .episode-thumb-2 {
              & { left: 15px; }
            }
            .episode-thumb-3 {
              & { left: 30px; }
            }
            .episode-thumb-4 {
              & { left: 45px; }
            }
            .episode-thumb-add-btn {
              & { left: 60px; }
            }
          }
          .episode-count {
            & { position: absolute; left: 100px; font-weight: 600; }
          }
        }
      }

      .people-enrolled-row {
        & { display: flex; min-height: 85px; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
        .people-list {
          & { display: flex; align-items: center; position: relative; }
          .people-template {
            & { width: 150px; display: flex; align-items: center; position: relative; }
            .people-thumb {
              & { display: flex; align-items: center; justify-content: center; position: absolute; height: 32px; width: 32px; background: #E9EAEE; border-radius: 100%; cursor: pointer; box-shadow: 0px 2px 5px #00000029; }
            }
            .people-thumb-1 {
              & { left: 0px; }
            }
            .people-thumb-2 {
              & { left: 20px; }
            }
            .people-thumb-3 {
              & { left: 40px; }
            }
            .people-thumb-4 {
              & { left: 60px; }
            }
            .people-thumb-add-btn {
              & { left: 80px; }
            }
          }
          .people-count {
            & { position: absolute; left: 7px; top: 20px; font-weight: 600; }
          }
        }
      }
      .small-title {
        & { font-weight: 600; margin-top: 1rem; }
      }
      .course-btn {
        & { text-transform: capitalize; border: 3px solid #1359E4; color: #1359E4; }
      }
      .supporting-files-btn {
        & { text-transform: capitalize; border: 3px solid #1359E4; background: #1359E4; color: #FFF; }
      }
      .supporting-files-episode-btn {
        & { margin: 1rem 0; }
      }

     .devo-title-comment {
      font-size: 14px; font-weight: bold; 
        span{ font-weight: normal; }
        .comment-count { font-size: 14px; font-weight: bold;  padding: 0 .5rem; border: 1px solid #Aaa; border-radius: 5px; cursor: pointer; }
      } 
      .tags-row{ margin: 16px 0px 16px 0px; display: flex; flex-wrap: wrap; gap: 15px;


        .number-icon{ font-size: 10px; background-color: #1359E4; color: white; border-radius: 50%; display: flex; padding: 1px 5px;
          align-items: center; justify-content: center;  width: 20px; height: 20px; position: absolute; right: -12px; top: -12px;
        }
        .author-tag {
          & {position: relative; font-size: 14px;color: #000000; border-radius: 5px;
             height: 24px; background-color: transparent; padding: 8px;  display: flex; align-items: center;
             justify-content: center; gap: 5px;
             font-weight: 600;
              // border: 1px solid #AAA;
               cursor: pointer; }
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
        }
        .topic-tag {
          & {  position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
            padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; }
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
          
        }
        .keyword-tag {
          & {position: relative; font-size: 14px;color:#9B9B9B; height: 24px; background-color: #E9EAEE; 
            padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; }
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
        }  
      }
    }
  }
}