.MuiAlert-standardError {
    &{ color: rgb(255 255 255) !important; background-color: rgb(254 86 0) !important; }
}

.MuiAlert-standardError .MuiAlert-icon{
    &{ color: white !important; }
}

.MuiAlert-message{
    &{ font-weight: bold; }
}  

.login-register{
    .MuiPaper-root{
        &{ position: absolute; top: 20px; left: 20px; }
    }
}