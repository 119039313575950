.featured-entity-modal-container {
  & { height: 600px; width: 600px; }
  header {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; height: 10%; }
    .close { cursor: pointer; }
  }
  .featured-entity-modal {
    & { height: 90%;  width: 100%; }
    .entity-list {
      & { position: relative; height: 100%; width: 100%; overflow-x: scroll; }
      .circular-progress {
        & { position: absolute; top: 42%; left: 47%; transform: translate(-50%, -50%); }
      }
      .entity {
        & { width: 100%; background: #f5f5f5; display: flex; align-items: center; justify-content: space-between; border-radius: 5px; padding: 10px; margin: 10px 0; }
        .add-entity-btn {
          & { color: #3149C3; border: 2px solid #3149C3; height: 35px; }
        }
      }
    }
  }

}

.general-image-upload-modal {
  & { height: 70vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }
  .title { width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
    h2 { margin: 0; color: #4D5865; }
    .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 10px; cursor: pointer;}
  }
  .easy-crop {
    & {  min-height: 70%; margin: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    img {
      & { height: 100%; width: 100%; object-fit: contain; }
    }
    .ReactCrop__child-wrapper {
      & { height: 100%; max-height: 100%; }
      img { object-fit: contain; }
    }
    .d-n-d { 
      & { height: 70%; width: 90%; border: 2px dashed #929292; border-radius: 25px; display: flex; align-items: center; justify-content: center;}
      .MuiButtonBase-root { height: 36px; background: #212A42; color: #fff; padding: 0 15px; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
    }
    .reactEasyCrop_Container {
      & { height: 100%; border-radius: 10px; }
    }
  }
  .crop-btns {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .slider {
      & { margin-left: 15px; width: 35%; }
      h3 { margin: 0; color: #4D5865; }
      .MuiSlider-root {
        & {  color: #212A42; height: 6px; }
      }
    }
    .MuiButtonBase-root {
      & { width: 180px; height: 36px; background: #212A42; color: #fff; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
      .MuiCircularProgress-root { height: 30px !important; width: 30px !important; }
    }
    .Mui-disabled { background: #efefef; color: #929292; }
  }
}

.upload-image-modal-container {
  & { height: 80vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }

  .title { 
    & { height: 50px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; }
    h2 { margin: 0; color: #3149C3; }
    .MuiSvgIcon-root { color: #3149C3; cursor: pointer;}
  }

  .crop {
    & {  height: calc(100% - 130px); padding: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 80%; width: 90%; border: 2px dashed #929292; border-radius: 10px; display: flex; align-items: center; justify-content: center;}
    }
  }

  .crop-btns {
    & { position: relative; height: 80px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .tips {
      & { position: absolute; bottom: -10px; left: 0px; display: flex; align-items: center; justify-content: flex-start; }
      &>span {
        & { color: #3149C3; font-size: 14px; }
      }
    }
  }
}

.build-site-modal {
  & { height: 270px; width: 500px; position: relative; }
  .modal-content {
    & { display: flex; align-items: center; justify-content: center; flex-direction: column; height: 200px; }
    p { font-weight: 600; font-size: 17px; padding: 0 1rem; text-align: center; }
  }
  .btn-container {
    & { width: 100%; display: flex; align-items: center; justify-content: space-evenly; }
  }
  .loading-overlay {
    & { height: 100%; width: 100%; position: absolute; top: 0; left: 0; background: #ffffff; display: flex; align-items: center; justify-content: center; flex-direction: column; }
    p { font-weight: 600; font-size: 17px; padding: 0 1rem; text-align: center; }
  }
}
.site-build-btn {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 100px; font-family: inherit; font-size: 15px; font-weight: 600; background: #1359E4; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
  &:disabled {  background: #afafaf !important; }
} 