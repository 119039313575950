
.reviews {
  & { position: relative; padding: 1rem !important;  }

  header {
    & { height: 60px !important; }
  }

  .deleting-reviews-container {
    & { position: absolute; top: 0; left: 0; display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%; width: 100%; background: #ffffff8f; z-index: 1000; }
  }

  .data-table {
    & { height: calc(100% - 60px) !important; }
  }

  .MuiTableContainer-root {
    & { height: 100% !important; }
    .MuiTableBody-root {
      & { height: 100%; }
    }
  }

  .MuiTableHead-root {
    position: sticky; top: 0; background: #FFF; z-index: 100;
    tr {
      & { background: #FFF; }
    }
  }
}

.review-category-container {
  & { display: flex; align-items: center; justify-content: center; }
  .course-category {
    & { width: 120px; padding: 10px 5px; display: flex; align-items: center; justify-content: center; gap:6px; color: #9F00B0; background: #ECCCEF; border-radius: 4px; }
  }
  .blog-category {
    & { width: 120px; padding: 10px 5px; display: flex; align-items: center; justify-content: center; gap:6px; color: #39BAE8; background: #D7F1FA; border-radius: 4px; }
  }
  .devotional-category {
    & { width: 120px; padding: 10px 5px; display: flex; align-items: center; justify-content: center; gap:6px; color: #F6CF3B; background: #FDF5D8; border-radius: 4px; }
  }
}

.delete-review {
  & { height: 100%; min-height: 100%; display: flex !important; align-items: center; justify-content: center; }
}

.action-btns {
  & { display: flex; align-items: center; justify-content: center; gap: 1rem; }

  .search-reviews {
    & {}
    &>input {
      & { padding: 0 1rem; height: 38px; width: 400px; border: 2px solid #aaa; border-radius: 5px; }
    }
  }
  
  .filter-courses-reviews {
    & { width: 50px; padding: 8px 5px; display: flex; align-items: center; justify-content: center; color: #9F00B0; background: #cccccc7d; border-radius: 4px;  cursor: pointer; }
  }
  .filter-blogs-reviews {
    & { width: 50px; padding: 8px 5px; display: flex; align-items: center; justify-content: center; color: #39BAE8; background: #cccccc7d; border-radius: 4px;  cursor: pointer; }
  }
  .filter-devotionals-reviews {
    & { width: 50px; padding: 8px 5px; display: flex; align-items: center; justify-content: center; color: #F6CF3B; background: #cccccc7d; border-radius: 4px;  cursor: pointer; }
  }
}

.review-text {
  & { font-weight: 400; font-size: 14px; color: #9B9B9B; }
}