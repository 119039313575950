.advertising-container {
    & { display: flex; flex-direction: column; padding: 20px; color: black; }
    
    button{ 
        &{ cursor: pointer; }
    }

    .advertising-heading-and-create-new-button-container {
      & { display: flex; justify-content: space-between; margin-bottom: 20px; padding: 0 25px; }

      h1{ color: black; }

      button{ display: flex; align-items: center; background: #1359E4; padding: 10px 15px; color: white; border: none; gap: 4px; }

    }
    
    .pages-container {
      & { display: flex; justify-content: space-between; }
    
      .advertising-pages {
        & { margin: 20px; padding: 15px; background: white; border-radius: 10px; }
    
        .image-wrapper {
          & { display: flex; flex-direction: column; }
    
          img {
            & { width: 100%; }
          }
    
          h4 {
            & { font-size: 14px; margin-bottom: 25px; }
          }
    
          .manage-and-other-buttons-container {
            & { display: flex; justify-content: space-between; width: 100%; }
    
            .manage-button {
              & { font-weight: 600; padding: 5px 20px; color: #1359E4; border: 1.5px solid #1359E4; background: transparent; border-radius: 3px; }
            }
    
            .edit-and-delete-button {
              & { display: flex; flex: 1; justify-content: flex-end; gap: 10px; }
    
              .edit-button, .delete-button {
                & { background: transparent; border: none; }
                
                svg{
                    &{ font-size: 1.2rem; }
                }
              }
            }
          }
        }
      }
    }   
    
    ::-webkit-scrollbar{ display: none; }
  }

  .advertising-img-container{

    &{ height: 100vh; overflow-y: scroll; padding: 40px; position: relative; width: 900px; margin: auto; }
    
    .add-advertisement-text{
      &{ font-size: 1.2rem; margin: 0; margin-top: 10px; }
    }
    
    svg{ border: 2px solid #57606f; border-radius: 50%; }
    
    .advertisement-text-icon-container{
      &{ display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; }
    }
    
    .main-img{ width: 100%; object-fit: cover; height: 1118px; box-shadow: 0px 0px 20px #cdcdcd; }
    
    .advertising-home-img-cropper1{
      &{width: 68%; height: 88px; position: absolute; top: 504px; left: 16%; border-radius: 5px; display: flex; justify-content: center; align-items: center; }
      img{ width: 100%; height:100%; object-fit: cover; border-radius: 5px; }
    }
    
    .advertising-home-img-cropper2{
      &{width: 68%; height: 150px; position: absolute; top: 1079px; left: 16%; border-radius: 5px; display: flex; justify-content: center; align-items: center; }
      img{ width: 100%; height:100%; object-fit: cover; border-radius: 5px; }
    }
    
    .advertising-home-img-cropper3{
      &{width: 72%; height: 89px; position: absolute; top: 1810px; left: 14%; border-radius: 0px; display: flex; justify-content: center; align-items: center; }
      img{ width: 100%; height:100%; object-fit: cover; }
    }

    .advertising-devotional-img-cropper1 {
      &{ width: 80%; height: 88px; position: absolute; top: 420px; left: 10%; border-radius: 5px; display: flex; justify-content: center; align-items: center; }
      img{ width: 100%; height:100%; object-fit: cover; border-radius: 5px; }
    }
    
    .advertising-devotional-img-cropper2 {
      &{ width: 80%; height: 144px; position: absolute; top: 879px; left: 10%; border-radius: 5px; display: flex; justify-content: center; align-items: center;} 
      img{ width: 100%; height:100%; object-fit: cover; border-radius: 5px; }
    }

    .advertising-courses-img-cropper1{
      &{ width: 72%; height: 92px; position: absolute; top: 407px; left: 14%; border-radius: 5px; display: flex; justify-content: center; align-items: center;} 
      img{ width: 100%; height:100%; object-fit: cover; }
    }
    
    .advertising-courses-img-cropper2{
      &{ width: 68%; height: 144px; position: absolute; top: 889px; left: 16%; border-radius: 5px; display: flex; justify-content: center; align-items: center;} 
      img{ width: 100%; height:100%; object-fit: cover; border-radius: 5px; }
    }
  }

  
  .advertising-home-container{
    .main-img{ height: 2387px; }
  }
  
  .advertising-devotional-container{
    .main-img{ height: 1066px; }
  }
  
  .advertisement-text-icon-container-with-img { 
    &{position: absolute; width: 100%; background: #00000078; color: white; height: 100%; justify-content: center; align-items: center; flex-direction: column; border-radius: 5px; }
    svg{ border: 2px solid white; }
   }