/* You can add global styles to this file, and also import other style files */

// importing other scss files
@use './Overview.scss';
@use './Reviews.scss';
@use './Advertising.scss';
@use './Featured.scss';
@use './Modal.scss';
@use './SupportingFiles.scss';
@use './Books.scss';
@use './Login.scss';
@use './Episodes.scss';

// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainfont : 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,900&display=swap');
$roboto : 'Roboto', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');
$sofia : 'Sofia', cursive;

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
$syne: 'Syne', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$courier : 'Courier Prime', monospace;


$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #e9eaee; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menufb

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
 $themecolor2: #ddd;



// Global styles
*, *::after, *::before {
  box-sizing: border-box;
}
html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif;}
h1 { font-size: 26px; font-weight: 700; color: $titlecolor1; }

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}

// Button Styles
.redbtn {background: $buttoncolor1;color: #fff;}
.redbtn:hover {color: #fff;background: $buttonaccentcolor1;}
.greybtn {background: $buttoncolor2;color: $titlecolor1;}
.greybtn:hover {color: $titlecolor1;}
.filterbtn {background: transparent !important;color: $titlecolor1;}

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}

.MuiChip-label { font-family: $mainfont; text-transform: uppercase; font-size: 10px; font-weight: 700; }


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $themecolor1 !important;}
.mat-ink-bar {height: 4px;}
.mat-tab-label-content {text-transform: uppercase;font-family: $mainfont;}
.mat-tab-labels {background: #fff;}

.aside {
    & {font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0;}

    .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
    .content { margin: 20px; text-align: justify; }
    .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }

}

.navbar {

    & {display: flex;flex-direction: column;width: 340px;background: #ced6e0; }

    a {
        & { display: block; width: 80%; margin: 0 auto; color: #FFF;
            text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6;
            border-top: 1px solid #277499; font-family: $mainfont; }

        &:first-of-type { border-top: none; }

        &:last-of-type { border-bottom: none; }

        &:hover { color: #ecf0f1; }
    }
}

.App { display: flex; font-family: $mainfont, sans-serif; }

/* -- Menu Main -- */
/* -- ========= -- */
.menu {

    & { height: 100vh; width: 220px; background: $themecolor1; color: #fff; transition: 1s; }
    .company-logo { height: 100px; text-align: center; display: flex; align-items: center; justify-content: center; margin: 0 auto; }
    .company-logo img { height: 80px; width: 80px; }

    nav { 
      & { height: calc(100% - 175px); margin: 0 0 0 12%; overflow-x: hidden; overflow-y: scroll;}
      
      .sidebar-item {
        & { display: flex; align-items: center; justify-content: center; gap: 10px; }
        span { font-weight: 600; }
      }
    }

    .site-build-btn-container {
      & { display: flex; align-items: center; justify-content: center; height: 75px; width: 100%; }
      .site-build-btn {
        & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 100px; font-family: inherit; font-size: 15px; font-weight: 600; background: #1359E4; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
        &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
        &:disabled {  background: #afafaf !important; }
      } 
    }

    nav a {display: flex;width: 100%;margin: 0 auto;color: #000;text-decoration: none;font-size: 1em;font-weight: 400;padding: 10px 5px;background: none;}
    nav .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }

    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: $titlecolor1;}

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}

    /*.container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }*/
}

.login-register {
  & { background: #3149C3; display: flex; flex-wrap: wrap; align-items: center; height: 100vh;}
  .login {
    & { margin-top: 0; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 580px;}
    .form-heading { 
      h1 { font-family: $mainfont; font-style: normal; font-weight: 700; font-size: 48px; line-height: 58px; text-align: center; color: #FFF; margin-top: 25px; margin-bottom: 4px; }
      p {font-family: $mainfont; font-style: normal; font-weight: 600; font-size: 24px; line-height: 29px; text-align: center; color: #DDD; margin: 0; }
    }
    .form-wrapper {
      & { margin: 63px 0px 0px;  }
      form { margin-bottom: 0px; }
      .helper-text { font-family: $mainfont; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #DDD; position: absolute; margin-left: -120px; }
      .MuiGrid-root {  max-width: 320px; }
      .MuiGrid-spacing-xs-4 { width: calc(100% + 16px); }
      .MuiGrid-spacing-xs-4 > .MuiGrid-item { padding: 8px; }
      .MuiFormLabel-root { font-size: 14px; } 
      .MuiInputBase-root { font-family: $mainfont; font-weight: 600; }
      .MuiOutlinedInput-input { font-size: 14px; background: #ddd; border-radius: 3px; }
    }
    .forgot-password {
      & { font-family: 'Montserrat'; font-style: italic; font-weight: 500;font-size: 16px; line-height: 20px; color: #FFFFFF; margin-left: -4px; }
      a { color: #F2D466; text-decoration: none; cursor: pointer; }
    }
    .sign-in {
      & { font-family: 'Montserrat'; font-style: italic; font-weight: 500;font-size: 16px; line-height: 20px; color: #FFFFFF; margin-left: -16px; }
      a { color: #F2D466; text-decoration: none; cursor: pointer; }
    }
    .remember-forgot-wrapper {
      & { margin: 10px 0px 30px; display: flex; flex-wrap: wrap; justify-content: space-between; }
      a { color: #007C89; text-decoration: none; font-size: 14px; font-weight: 400; }
    }
    .no-account-wrapper {
      & { margin-top: 20px; color: #292F36; font-size: 16px; font-weight: 400; }
      a { color: #007C89; text-decoration: none; }
    }
    .outlineBtn { margin: 2rem auto; margin-left: -8px; width: 100%; background: #ddd; border-radius: 3px; font-family: $mainfont; font-style: normal; font-weight: 700; height: 54px; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #3149C3;}
    .visibility { cursor: pointer; }

  }
}


.main {
  & { height: 100vh; width: 100%; color: $titlecolor1;}
  h1 { color: $titlecolor1; }

  .container { 

    
    & { width: 100%; height: 100vh; padding: 0 1rem; overflow-y: auto; }

    .create-devotional{
      display: flex;
      gap:10px;
    }
  //   & { height: 100%; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; }
  // .create-devotional {
  //   & { width: 100%; height: 10%; padding: 10px 16px; display: flex; align-items: center; justify-content: flex-end; }
  //   .create-devotional-btn { background: #1359e4; color: #FFF; }
  // }

    header {       
      & { display: flex; align-items: center; justify-content: space-between; width: 100%; height: 80px; }
      .header-cta { display: block; cursor: pointer; background: #1359e4; color: #FFF; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; font-family: $mulish; }
    }

  }

  .MuiTable-root { color: $titlecolor1; font-family: $mainfont, sans-serif; }
  .MuiTableCell-head { color: $titlecolor1; font-weight: 700; font-family: $mainfont, sans-serif; }
  .MuiTableCell-body { color: $titlecolor1; font-family: $mainfont, sans-serif; }


  .data-table {
    & { width: 100%; height: calc(100% - 80px); padding: 10px 0; display: flex; align-items: flex-start; justify-content: center; }

    .MuiTableRow-root { & { border-bottom: 1px solid lightgray;}
      .MuiTableCell-root { 
        & { border-bottom: none;}
        .drag-book-handle {
          & { display: flex; align-items: center; justify-content: center; gap: 10px; }
        }
      }
    }
  }


  .table-tool { cursor: pointer; }
}


// drawer css here 

.MuiPaper-root.MuiDrawer-paper { background-color: #F6F7FA; }
.item{
  display: block !important;
  margin: 8px !important;
}
.drawer {
  & { background-color: #F6F7FA; width: 80vw; height: 100vh; height: auto; overflow: auto; padding: 0 2rem }
  .tool-button { height: 24px; width: 24px; margin-right: 10px; cursor: pointer; color: #007C89; }
  .active { border: 1px solid blue; }
  .disable { border: none; }
  .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  header {
    & { margin: 8px;  color: #000000; width: auto; max-width: 100%; height: 50px;  display: flex; align-items: center; }
      h1 { margin: 0; font-size: 24px; color: #000000; align-items: center; display: flex;
      
      .back-button { height: 24px; width: 24px; margin-right: 10px; }
    }
  }

  .MuiTabs-flexContainer { background: #ffffff; }

  

  section { 
    
    & { min-height: calc(100% - 150px); height: calc(100% - 50px); width: 100%; overflow-y: auto; padding-bottom: 150px; }

    .edit-blog-post {
      margin-top: 1rem;
    }



   
    .editor-div{
      margin: 0 auto;
      margin-bottom: 40px;
      width: 80vw;
      max-width: 1076px;
      background-color: #FFFFFF;
      padding: 40px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 8px;

      span{
        font-size: 12px;
        color:#9A9A9A;
      }

      .title-header{
        width: 65%;
        padding-right: 40px;
        .author-row{
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 16px;
          .author-image{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            object-fit: cover;
            background-color: #000000;
          }
          
          .author-tag {
            & {  position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
              padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
            .remove-author { height: 16px; width: 16px; cursor: pointer; }
            .add-author { height: 18px; width: 18px; cursor: pointer; }
            
          }
        }

        .edit-blog-post {
          & { font-size: 14px; font-weight: 600; border: 1px solid #ccc; border-bottom: none; padding: .5rem .5rem; background: #e3e8f0; }
        }
        .quill { margin-bottom: 1rem; }
        .blog-snippet-quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
          height: 150px;
        }
        .blog-snippet-quill .ql-toolbar.ql-snow {
          display: none;
        }

      }
      .content-container{
        display: flex;
        gap:40px;

        .text-editor-container{
          width: 65%;
          
          
          .image-container{
            margin-bottom: 24px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .post-image{
              width: 100%;
              max-width: 100%;
              max-height: 360px;
              border-radius: 10px;
              margin: 0;
              padding: 0;

              
            }

            .upload-image-buttons{
              position: absolute;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .upload-button{

                background-color: #1359E4;
                color: white;
              }

              .remove-button{

                background-color: transparent;
                border: 2px solid #1359E4;
                .buttons{
                  color:#1359E4;
                }
              }

              .buttons{
                color:white;
              }
            
            }
          }
          
          
        }
        .myeditor{
          
          .ql-snow{ border: none !important; 
          .ql-editor{
            padding: 0 !important; margin: 0 !important;
          }
        }
          
        }
        

      }

      .second-column{
        .trending-text{
          font-size: 16px;
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          color:#9B9B9B;
        }
        .trending-items{
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          gap:16px;
          p{
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            color: #9B9B9B;
          }
          
          .trending-image{
             height: 104px;
             border-radius: 10px;
          }

        }
        
      }
      
      
    }
   
  }

  .blog-edit-section { 
    
    & { width: 100%; overflow-y: scroll; padding: 5px 0; }

    .edit-blog-post {
      margin-top: 1rem;
    }

    .editor-div{
      margin: 20px auto;
      width: 80vw;
      max-width: 1076px;
      background-color: #FFFFFF;
      padding: 40px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 8px;

      .blog-author {
        font-size: 14px;
        color:#5c5c5c;
      }
      .remove-blog-author {
        font-size: 14px;
        color:#5c5c5c;
        margin-top: 7px;
      }

      .title-header{
        width: 65%;
        padding-right: 40px;
        .author-row{
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 16px;
          .author-image{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            object-fit: cover;
            background-color: #000000;
          }
          
          .author-tag {
            & {  position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
              padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
            .remove-author { height: 16px; width: 16px; cursor: pointer; }
            .add-author { height: 18px; width: 18px; cursor: pointer; }
            
          }
        }

        .edit-blog-post {
          & { font-size: 14px; font-weight: 600; border: 1px solid #ccc; border-bottom: none; padding: .5rem .5rem; background: #e3e8f0; }
        }
        .quill { margin-bottom: 1rem; }
        .blog-snippet-quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
          height: 150px;
        }
        .blog-snippet-quill .ql-toolbar.ql-snow {
          display: none;
        }

      }
      .content-container{
        display: flex;
        gap:40px;

        .text-editor-container{
          width: 65%;
          
          
          .image-container{
            margin-bottom: 24px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #aaa;
            border-radius: 5px;
            width: 580px;
            max-width: 580px;
            max-height: 360px;
            min-height: 360px;

            img {
              width: 580px;
              max-width: 580px;
              max-height: 360px;
              min-height: 360px;
              border-radius: 10px;
              margin: 0;
              padding: 0;
              object-fit: contain;
            }
            iframe {
              width: 580px;
              max-width: 580px;
              max-height: 360px;
              height: 300px;
            }

            .placeholder {
              & { height: 200px; width: 200px; }
            }

            .upload-image-buttons{
              position: absolute;
              display: flex;
              gap: 20px;
              inset: auto auto 10px 5px;

              .upload-button{
                height: 30px;
                background-color: #1359E4;
                color: white;
                font-size: 12px;
              }

              .remove-button{
                height: 30px;
                background-color: transparent;
                border: 2px solid #ffffff;
                color: #FFF;
                .buttons{
                  color:#ffffff;
                }
                font-size: 12px;
              }

              .buttons{
                color:white;
              }
            
            }
            .image-toggle-button {
              position: absolute; inset: auto 5px 5px auto; background: #ffffff87; padding: 0 1rem; border-radius: 5px; color: #000000;
            }
            .upload-video-input {
              position: absolute; width: 300px; height: 30px; inset: auto auto 10px 5px; background: #272727; border-radius: 5px; color: #FFF;
              input { background: #272727; border: none; color: #FFF; width: 100%; height: 30px; border-radius: 5px; padding: 0 5px; }
              input:focus { outline: none; }
            }
          }
          
          
        }
        .myeditor{
          
          .ql-snow{ border: none !important; 
          .ql-editor{
            padding: 0 !important; margin: 0 !important;
          }
        }
          
        }
        

      }

      .second-column{
        .trending-text{
          font-size: 16px;
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          color:#9B9B9B;
        }
        .trending-items{
          margin-bottom: 16px;
          display: flex;
          align-items: flex-start;
          gap:16px;
          p{
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 14px;
            color: #9B9B9B;
          }
          
          .trending-image{
             height: 104px;
             border-radius: 10px;
          }

        }
        
      }
      
      
    }
   
  }



  .double-column-form { 
    & { display: flex; align-items: center; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}
.blog-drawer-container { 
  & { width: 100vw !important; height: 100vh; padding: 0; overflow: hidden; background: #F6F7FA; }

  .blog-drawer {
    & { width: 100%; height: 100%; display: grid; grid-template-rows: 160px 1fr 100px; }

    .blog-drawer-header {
      & {  }
      header {
        & { height: 40px; }
      }
      .blog-webpage-clone {
        & { width: 100%; background: #FFF; height: 36px; margin-top: 3rem;  }
        .webpage-clone {
          & { position: relative; width: 100%; height: 100%; box-shadow: 0px -5px 10px 0px #aaaaaa40; }
          .webpage-link {
            & { position: relative; width: 100%; height: 100%;  display: flex; align-items: center; z-index: 10;  }
            .link-icon { margin: 0 5px; }
            .link-container {
              & { flex: 1; display: flex; align-items: center; background: #E9EAEE; height: 80%; margin: 0 1rem; border-radius: 20px; }
              span {
                & { margin-left: 1rem; font-size: 14px; font-weight: 700;  }
              }
              input { 
                & { flex: 1; font-size: 14px; border: none; background: transparent; padding-top: 3px; margin-right: 2rem; margin-left: -1px; font-weight: 700; font-family: "Nunito", sans-serif; }
                &:focus { outline: none; }
              }
            }
          }
          .title-tab {
            & { z-index: 9; position: absolute; display: flex; align-items: center; gap: 10px; height: 35px; width: 300px; padding: 0 10px; background: #ffffff; top: -33px; left: 16px; border-top-right-radius: 15px; border-top-left-radius: 15px; box-shadow: 0px -5px 10px 0px #aaaaaa40; }
            img { height: 20px; width: 20px; }
            span { flex: 1; white-space: pre; overflow: hidden; text-overflow: ellipsis; font-size: 14px; font-weight: 600; }

          }
          .action-buttons-div {
            & { position: absolute; top: -45px; align-items: center; right: 1rem;  display: flex; gap: 10px; }
              .save-button{ background-color: #1359E4; font-size: 16px !important; height: 30px; }
              .cancel-button{ background-color: transparent; height: 30px; color: #1359E4; border: 1px solid #1359E4; font-size: 16px !important; font-weight: 600; font-family: "Nunito";}
          }
        }

        .meta-tags-container {
          & { position: absolute; width: 100%; background: #FFF; z-index: 100; padding: 0 2rem; padding-bottom: 1rem; box-shadow: 0px 15px 15px 0px #aaaaaa80; }
          .snippet-textarea {
            & { padding: 1rem; resize: none; width: 100%; border-radius: 4px; border-color: #bbb; }
            &:hover {
              & { border-color: #111; }
            }
            &:focus {
              & { border-width: 2px; outline: none; border-color: #1359E4; }
            }
          }
        }
      }
      .editor-toolbar-container {
        & { position: relative; box-shadow: 0px 5px 15px 0px #aaaaaa30; }
        .editor-toolbar-control {
          & { background: #ffffff; position: absolute; inset: auto auto -25px auto; height: 30px; width: 30px; display: flex; align-items: center; justify-content: center; cursor: pointer; box-shadow: 0px 5px 15px 0px #aaaaaa30; border-radius: 0 0 5px 5px; }
        }
        .tools-div{
          background: #ffffff;
          display: flex;
          height: 40px;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          border: 0px;
          .tools-button{
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
          
            .ql-formats{
              display: flex;
            }
          }
          .action-buttons-div{
            display: flex;
            gap: 10px;
            .save-button{
            background-color: #1359E4;
            font-size: 16px !important;
            height: 30px;
            }
            .cancel-button{
              background-color: transparent;
              height: 30px;
              color: #1359E4;
              border: 1px solid #1359E4;
              font-size: 16px !important;
              font-weight: 600;
              font-family: "Nunito";
            }
          }
      }
    }
  }

    .tags-container{
      
      margin: 0 auto;
      display: flex;
      padding: 1rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      width: 100%;
      min-height: 100px;
      border: 0px;
      .topic-row{
        width: 41%;
        max-width: 41%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        min-height: 100px;
        border-left: 1px solid #AAA;
        padding: 0 5px 0 5px;
        .single-tag {
          position: relative; font-size: 14px; color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
            padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
        }
        .single-tag:has(.add-author){
          & { background: #FFF; border: 1px solid #111; color: #111;  cursor: pointer; }
        }
      }
      .tags-row{
        width: 41%;
        max-width: 41%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        min-height: 100px;
        border-left: 1px solid #AAA;
        padding: 0 5px 0 5px;
        .single-tag {
          position: relative; font-size: 14px; color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
            padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
        }
        .single-tag:has(.add-author){
          & { background: #FFF; border: 1px solid #111; color: #111;  cursor: pointer; }
        }
      }
  
      .status-container{
        border-left: 1px solid #AAA;
        width: 13%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 100px;
        .status-button{
          padding: 7px 16px;
          border-radius: 50px !important;
          color:#9B9B9B; 
          background-color: #E9EAEE;
          cursor: pointer;
        }
        .published {
          background-color: #1ABC9C; color: #FFF;
        }
      }
      
  
  
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {

}

::-webkit-scrollbar-thumb {
  background-color: #919191;
  outline: 1px solid #ffffff;
  border-radius: 2px;
}



//Drawer font for React Quill

.ql-font-roboto {
  font-family: $roboto;
}
.ql-font-sofia{
  font-family: $sofia;
}

.ql-font-nunito {
  font-family: $mainfont;
}
.ql-font-munito{
  font-family: $mulish;
}

.ql-font-syne{
  font-family: $syne;
}

.ql-font-courier{
  font-family: $courier;
}























// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

  // & { width: 1}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; left: 0px; overflow-y: scroll; }

  .openlightbox { opacity: 1 !important; }

  .lightbox {
    & { position: absolute; border-radius: 30px; text-align: center; padding: 0px; background: #FFF; max-width: 680px; z-index: 10001; min-height: 100px; top: 64px; font-family: $mainfont;  left: 50%; transform: translate(-50%, 0px); }

    h1 { color: #1F4E96; font-size: 1.5em; margin: 0px;
    padding: 20px 0px; border-top-right-radius: 30px; border-top-left-radius: 30px; }

    h2 { font-size: 1.4em;color: #1F4E96; font-weight: 800; font-family: "proxima-nova", sans-serif; }

    .lightboxfooter { justify-content: space-between !important; padding: 15px 30px; }
    .lightboxfooter button { font-family: $mainfont; background: #f1f2f6; font-weight: 600; }

    form { margin-bottom: 0px; }
    .mat-form-field { width: 100%; }
  }

  .defaultLightbox > div { padding: 30px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
  }



// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

// ========================= Smiler CSS ============================== //

// =================== Global CSS ===================



// theme colors
$smilercolor1: #FFEB33;

// background
$bgmain: #f6f7fa; // body

// form
$formtext: #1F4E96;
$formbg: #eff5ff;

// classes
body { background-color: $bgmain; font-family: $mainfont; }
.full-frame { max-width:1440px; margin:0 70px; width: calc(100% - 140px); }
.medium-frame { max-width:991px; margin:0 70px; width: calc(100% - 140px); }
.cdk-overlay-container { z-index: 10002; }

// spinner
.matspinner {display: flex;justify-content: center;align-items: center;min-height: 50vh;}

// buttons
.yellowBtn { background-color: $smilercolor1 !important; color:#292F36 !important; font-family: $mainfont; }
.outlineBtn { background-color: none !important; color: #292F36 !important; border: 1px solid #292F36 !important; font-family: $mainfont; }
.yellowBtn.mat-button-disabled { opacity: 0.5; }
.plainBtn { background-color: #ffffff !important; color:#007C89 !important; font-family: $mainfont; }
.plainBtn.mat-button-disabled { opacity: 0.5; }
.btn-large { box-shadow: none !important; width: 100%; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }
.btn-small { box-shadow: none !important; min-width: 120px !important; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }
.action-buttons-cell {
  & { display: inline-flex !important; }
  .action-icon { cursor: pointer; }
}
.action-icon { cursor: pointer; }

.smiler-button-loader {
  & { transition: 0.1s !important; }
  &.smiler-button-loading { min-width: 50px !important; height: 50px !important; border-radius: 100% !important; padding: 0px !important; }
  .submit-loader { width: 40px; }
}

// form field
.form-field {
  .field-label { color: #292F36; font-size: 16px; font-weight: 500; }
  .mat-form-field { width:100%; }
  input[type="password"], input[type="text"] { border: none !important; padding: 0px; height: auto; background-color: transparent; }
  .mat-form-field {
    & { width:100%; font-family: $mainfont; }
    // .mat-form-field-wrapper { margin: 0; padding: 0; }
    .mat-form-field-infix { padding: 12px 0 20px 0; border-top-width: 12px; }
    .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
     input::placeholder { font-size: 14px; color: #D0D5DB; font-family: $mainfont; }
     input { font-size: 14px; color: $formtext !important; font-family: $mainfont; }
     textarea { border:none;  font-size: 14px; color: $formtext; }
     textarea::placeholder { color: #D0D5DB; }
     .mat-form-field-outline { background: #F2F4FC; border-radius: 12px; }
     .mat-form-field-outline-thick .mat-form-field-outline-start,
     .mat-form-field-outline-thick .mat-form-field-outline-end,
     .mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-width: 0px ;
      }
      .mat-form-field-suffix { top: 7px; }
      .mat-form-field-suffix .material-icons { color: #E2E7F4; }
      .mat-form-field-suffix .material-icons.valid { color: #718194; }
      .mat-error { font-weight: 600; }
   }
}

// checkbox
.circle-checkbox {
  .mat-checkbox-frame { border-color: #C7C9D9; border-radius: 100%; border-width: 1px; background-color: #F2F2F5 ; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #FFFD00; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #FFFD00; }
  .mat-checkbox-checkmark-path { stroke: #292F36 !important; }
  .mat-checkbox-label { font-weight: 400; color: #4D5865; font-size: 14px; }
}

.circle-large-checkbox {
  .mat-checkbox-frame { border-color: #007C89; border-radius: 100%; border-width: 2px; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #007C89; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #007C89; }
  .mat-checkbox-checkmark-path { stroke: #fff !important; }
  .mat-checkbox-inner-container { height: 24px; width: 24px; }
}

// slider
.yellow-slider.mat-slider-horizontal {
  & { padding: 0px; }
  .mat-slider-wrapper, .mat-slider-track-wrapper, .mat-slider-track-background, .mat-slider-ticks-container, .mat-slider-ticks { height: 4px; }
  .mat-slider-track-fill { height: 6px; }
  .mat-slider-track-fill, .mat-slider-thumb, .mat-slider-thumb-label { background-color: #FFDB15; }
  .mat-slider-thumb { width: 30px; height: 30px; bottom: -15px; }
  .mat-slider-track-background { background-color: #FFF7AD; }
  .mat-slider-track-wrapper { border-radius: 4px; }
}

// =================== Global CSS ===================

// =================== Header ===================

.header {
  & { position: fixed; background: #fff; z-index: 99; }
  .header-toolbar {
    & { padding: 0; justify-content: space-between; }
    // logo
    img.logo { margin-top: -10px; }
    // search
    .mat-form-field {
     & {  background: $formbg; width: 320px; border-radius: 5px; }
     .mat-form-field-wrapper { margin: 0; padding: 0; }
     .mat-form-field-infix { padding: 2px 0 14px 0; border-top-width: 10px; }
     .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
      input , input::placeholder { font-size: 14px; color: $formtext; font-family: $mulish; }
      .input-search { margin-right: 5px; }
    }
    // header right
    .header-right {
      & { display: flex; align-items: center; flex-wrap: wrap; }
     img { margin: 0px 12px; cursor: pointer; }
    }
  }
  .full-frame { width: calc(100% - 140px); }
}
.header-spacer { padding-top: 64px; }
.public-header {
  & { text-align: right; margin-top: 30px; }
  img { width: 50px; }
}
// =================== Header ===================

// =================== layout ===================

.app-wrapper {
  & { display: flex; flex-wrap: wrap; padding-top: 30px; }
  app-menu { flex: 0 0 300px; margin-right: 15px; }
  router-outlet + * { flex: 1 0 auto; }
}

// ========== menu ==========
.layout-menu {
  & { width: 250px; }
  .user-info {
    & { display: flex; flex-wrap: wrap; align-items: center; border-bottom: 1px solid #C6C6C6; padding-bottom: 15px; }
    img { max-width: 52px; border-radius: 40%; }
    .user-name { margin-left: 10px; font-size: 14px; flex: 1 0 auto; }
    .menu-icon {
      & { display: flex; flex-wrap: wrap; cursor: pointer; }
      span { width: 3px; height: 3px; border-radius: 100%; border: 1px solid #3483FA; margin: 0px 1px; }
    }
  }
  .smiler-menu {
    & { margin-top: 15px; }
    .menu-item {
      & { display: flex; flex-wrap: wrap; align-items: center; margin: 15px 0px; cursor: pointer; }
      .menu-text { margin-left: 15px; color:#656565; font-size: 16px; flex: 1 0 auto; }
      .menu-icon { display: flex; align-items: center; justify-content: center; width: 45px; height: 45px; border-radius: 100%; background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%); box-shadow: 0px 12px 40px rgba(202, 202, 238, 0.4); }
      .menu-notifications { color: #1F4E96; font-size: 12px; background-color: #FFFD00; padding: 2px 8px; border-radius: 10px; font-weight: 700; }
      &.active {
        .menu-icon { background: linear-gradient(90deg, #FFFD00 0%, #FFFFB3 100%); box-shadow: 0px 8px 16px rgba(255, 253, 0, 0.3); }
        .menu-text { font-weight: 600; color: #323232; }
      }
    }
  }
}
// ========== menu ==========

// =================== layout ===================

// =================== login ===================

// =================== login ===================



.create-devotional-container {
  & { width: 100%; height: 100%;  }
  .devotional-header {
    & { height: 5%; width: 100%; display: flex; align-items: center; justify-content: space-between; }

  }
  .create-container {
    & { width: 100%; height: 80%; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 16px;  }
  }
}

.MuiPaper-root.MuiTableContainer-root {
  & { height: 100%; }
}


.ql-toolbar.ql-snow + .ql-container.ql-snow { height: 400px; }

.table-body {
  overflow-x: hidden; overflow-y: scroll;
}

.devo-description {
  & { max-width: 225px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
}

.devo-title {
  & { max-width: 350px;  }
  .devo-title-text { font-weight: 600; }
  .author-tags {
    & { display: flex; flex-wrap: wrap; align-items: center; gap: 5px; }
    .author-tag {
      & { height: 24px; background: #e3e8f0; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
      .remove-author { height: 16px; width: 16px; cursor: pointer; }
      .add-author { height: 18px; width: 18px; cursor: pointer; }
    }
  }
  .author-blog-tags {
    & { display: flex; flex-wrap: wrap; align-items: center; gap: 5px; border: 1px solid #aeaeae; border-radius: 5px; padding: 8px; margin-bottom: 8px; }
    .author-tag {
      & { height: 24px; background: #e3e8f0; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
      .remove-author { height: 16px; width: 16px; cursor: pointer; }
      .add-author { height: 18px; width: 18px; cursor: pointer; }
    }
  }
}

.action-buttons-cell {
  & { width: 115px; }
}
.action-buttons-t-cell {
  & { width: 115px; }
}

.devo-tags {
  & { max-width: 200px;  }
  .devo-title-text { font-weight: 600; }
  .author-tags {
    & { display: flex; flex-wrap: wrap; align-items: center; gap: 5px; }
    .author-tag {
      & { min-height: 24px; background: #e3e8f0; padding: 5px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
      .remove-author { height: 16px; width: 16px; cursor: pointer; }
      .add-author { height: 18px; width: 18px; cursor: pointer; }
    }
  }
}

.devo-topics {
  & { max-width: 200px;  }
  .devo-title-text { font-weight: 600; }
  .author-tags {
    & { display: flex; flex-wrap: wrap; align-items: center; gap: 5px; }
    .author-tag {
      & { min-height: 24px; background: #e3e8f0; padding: 5px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; }
      .remove-author { height: 16px; width: 16px; cursor: pointer; }
      .add-author { height: 18px; width: 18px; cursor: pointer; }
    }
  }
}

.devo-status {
  & { max-width: 115px; width: 115px; }
  .author-tags {
    & { display: flex; justify-content: center; align-items: center; gap: 5px; }
    .author-tag {
      & { height: 20px; background: #e3e8f0; padding: 7px 10px 5px 10px; border-radius: 8px; display: flex; align-items: center; justify-content: center; gap: 5px; text-transform: uppercase; font-size: 10px; cursor: pointer; }
      .remove-author { height: 16px; width: 16px; cursor: pointer; }
      .add-author { height: 18px; width: 18px; cursor: pointer; }
    }
  }
  .published { background: #1abc9c !important; color: #fff; }
}

.devo-img {
  & { cursor: pointer; display: flex; align-items: center; justify-content: center; height: 180px; width: 180px; max-height: 180px; max-width: 180px; }
  .devo-img-container {
    & { height: 150px; width: 180px; max-height: 150px; max-width: 180px;  display: flex; align-items: center; justify-content: center; }
    .devotional-img { object-fit: contain; height: 150px; max-width: 150px; width: 150px; }
  }
  .book-img-container{
    height: 140px;
    width: 140px;
    
    
    .devotional-img { object-fit: contain; height: 100%; max-width: 140px; width: 100%; }
  }
  .placeholder-img {
    & { padding: 10px 0; max-height: 165px; min-height: 165px; max-width: 165px; min-width: 165px; border-radius: 10px; border: 2px dashed #656565; display: flex; flex-direction: column; align-items: center; justify-content: center; }
    img { object-fit: contain; height: 70%; max-width: 70%; width: 70%; }
    .placeholder-text { height: 30%; text-align: center; width: 90%; }
  }
}

.devo-author {
  & { max-width: 200px;  }
}

.author-list {
  & { display: flex; flex-wrap: wrap; align-items: center; gap: 16px; margin-bottom: 16px; }
  .author-tag {
    & { height: 24px; background: #e3e8f0; padding: 10px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px;  }
    .remove-author { height: 16px; width: 16px; cursor: pointer; }
  }
}

.select-author {
  & { margin-bottom: 16px; }
}
.attach-modal{
  position: relative; height: auto; width: 550px;  align-items: center;  
  .footer{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    Button{
      width: 100%;
    }
  }
}
.choose-background-modal{
  position: relative; height: auto; align-items: center;  
  .active{
    background-color: #D0DEFA;
    color: #1359E4;
  }
  .btn{
    color:#000000;
  }
  
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
  .color-picker{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .background{
    height: 52px;
    background-color: red;
    align-items: center;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    img{
      margin-right: 10px;
    }

  }
  .footer{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.color-picker{
  height: 750px;
  width: 600px;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
  .background{
    width: 100%;
    .sketch{
      width: 100%;
    }
  }
}
 
.upload-image-modal {
  & { position: relative; height: 600px; width: 600px; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }
  .image-container {
    & { height: 90%; width: 100%; display: flex; align-items: center; justify-content: center; }
    img { width: 100%; height: 100%; object-fit: contain; }
    .d-n-d {
      & { width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }
      .dropzone {
        .text-center {
          & { display: flex; flex-direction: column; align-items: center; justify-content: center; }
          button { background: #3149C3; color: #FFF; }
        }
      }
    }
  }
  .upload-btn {
    button { background: #3149C3; color: #FFF; }
    button:disabled { background: #5c6dc4; color: #FFF; }
  }

  .close {
    & { position: absolute; top: 0px; right: 0px; cursor: pointer; background: #FFF; border-radius: 5px; }
  }
}

.upload-video-modal{
  position: relative; height: auto; width: 600px; display: flex; flex-direction: column; align-items: center; justify-content: space-between;
  .head{
    display: flex; justify-content: space-between; align-items: center; width: 100%;
  }
  .v-d-n-d{
    padding: 16px;
    border: 1px dashed #9B9B9B;
    width: 100%;
    background-color: #E9EAEE;
    color: #9B9B9B;
    font-size: 20px;
    border-radius: 8px;

    .btn{
      background-color: #C6C6C6;
      color: #9B9B9B;
    }
    
  }
  .upload-card{
    width: 100%; gap: 20px;
    padding: 8px 16px;
    margin: 8px 0px;
    border: 1px solid #C6C6C6; display: flex; align-items: center; justify-content: space-between;
    border-radius: 8px;
  }

}

.crop-container {
  & { height: auto; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-between;}
  .title { width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
    h2 { margin: 0; color: #4D5865; }
    .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 10px; cursor: pointer;}
  }
  .easy-crop {
    & { margin: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    img {
      & { height: 100%; width: 100%; object-fit: contain; }
    }
    .ReactCrop__child-wrapper {
      & { height: 100%; max-height: 100%; }
      img { object-fit: contain; }
    }
    .d-n-d { 
      & { height: 70%; width: 90%; border: 2px dashed #929292; border-radius: 25px; display: flex; align-items: center; justify-content: center;}
      .MuiButtonBase-root { height: 36px; background: #212A42; color: #fff; padding: 0 15px; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
    }
    .reactEasyCrop_Container {
      & { height: 100%; border-radius: 10px; }
    }
  }
  .crop-btns {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .slider {
      & { margin-left: 15px; width: 35%; }
      h3 { margin: 0; color: #4D5865; }
      .MuiSlider-root {
        & {  color: #212A42; height: 6px; }
      }
    }
    .MuiButtonBase-root {
      & { width: 180px; height: 36px; background: #212A42; color: #fff; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
      .MuiCircularProgress-root { height: 30px !important; width: 30px !important; }
    }
    .Mui-disabled { background: #efefef; color: #929292; }
  }
}

.edit-author-container {
  & { max-height: 600px; width: 575px; min-width: 550px; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }
    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-author-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 16px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .authors-menu {
      & { width: 100%;  max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .no-author {
          & { text-align: center; justify-content: center; color: #ff8787; }
        }
        .add-new-author {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .author-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
    }
  }
}

.edit-topics-container {
  & { max-height: 600px; width: 575px; min-width: 550px; max-width: 550px; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-topic-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 16px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .topics-menu {
      & { width: 100%;  max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-topic {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .topic-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-direction: column; align-items: flex-start; }
      .permission-row {
        & {  }
      }
    }
    .permission-list {
      & { margin-bottom: 16px; display: flex; flex-direction: column; align-items: flex-start; }
      .permission-row {
        & { display: flex; align-items: center; width: 100%; padding: 0.4rem; margin: 0.2rem 0; border-radius: 5px; background: #00000010; }
        .checkbox-div {
          & { margin-left: auto; }
        }
      }
    }
  }
}

.edit-tags-container {
  & { max-height: 600px; width: 575px; min-width: 550px;  }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-tag-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 16px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .tags-menu {
      & { width: 100%; min-height: 50px; max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-tag {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .tag-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
    }
  }
}

.topic-featured {
  .author-tag.MuiSvgIcon-root {
    stroke: none;
    fill: #bdbdbd;
  }
  .author-tag.published {
    stroke: #1359e4;
    fill: #1359e4;
  }
}


.upload-pdf-container {
  & { position: relative; height: 500px; width: 500px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
  .d-n-d {
    & { width: 100%; height: 90%; display: flex; align-items: center; justify-content: center; }
    .dropzone {
      & { height: 100%; width: 100%; }
      .text-center {
        & { display: flex; flex-direction: column; align-items: center; justify-content: center; }
        button { background: #3149C3; color: #FFF; }
      }
    }
  }
  .btn-container {
    & {  }
    .upload-pdf-btn {
      & { background: #3149C3; color: #FFF; margin: 0 1rem; }
      &:hover { background: #2b40aa; }
    }
  }

  .pdf-container {
    & {  width: 100%; height: 90%; display: flex; align-items: center; justify-content: center; }
    p { width: 90%; overflow: hidden; text-overflow: ellipsis; }
    .close-btn {
      & { margin-left: auto; cursor: pointer; }
    }
  }
  .close-modal-btn {
    & { position: absolute; right: 10px; top: 10px; cursor: pointer; }
  }
}

// Reviews Page Styles 

.table-row{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 10px 3px 10px #0000000A;
  border-radius: 8px;
  margin-bottom: 1rem;
  
  .TableCell{
    font-weight: bold;
  color: black;
  align-items: center;
  }
}
.reviews {
  & {}
  .profile-img { width: auto; height: 50px; border-radius: 50%; }
}

// Modals

.modal{
  padding: 1rem;
  .heading{
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    .remove-author{
      cursor: pointer;
    }
  }

  .warning {background:rgb(241, 240, 225); padding: 10px; }
  .error {background:rgb(249, 214, 214); padding: 10px; }
  .footer{
    padding-top: 20px;
    width: 100% !important;
    color: white;
    display: flex;
    gap: 10px;
    .cancel-btn{
      
      
     width: 100%;
    }
    .confirm-btn{
      width: 100%;
      background-color: #1359E4;
      color: white;
      
    }
  }
}

//blogs page card Ui

.flex-table{
  
  & { display: flex; flex-wrap: wrap; gap: 15px; height: calc(100% - 130px); overflow-y: scroll; }
  
  .author-card{
    margin-top: 140px;
    overflow: visible;
    padding: 86px 16px 16px 16px; width: 32%; max-width: 260px; min-width: 260px;  background: #FFFFFF; border-radius: 8px; position: relative; box-shadow: 0px 3px 10px #0000000A !important;
    max-height: 240px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;

    .avatar{
      background-color: #3149c3;
      font-size: 40px;
      font-weight: normal !important;
      text-transform: uppercase;
      position: absolute;
      height: 140px !important;
      width: 140px !important;
      top: -70px;
      box-shadow: 0px 2px 10px #0000000F;
      
    }
    .name{
      font-size: 20px;
      font-weight: bold;
    }
    .des{
      font-size: 14px;
      text-align: center;
      display: none;
    }

    .email{
      font-size: 14px;
      font-weight: bold;
    }
  }

  

  
  .blog-card{ padding: 16px 16px 16px 16px; width: 32%; max-height: 550px; max-width: 400px; min-width: 300px;  background: #FFFFFF; border-radius: 8px; position: relative; box-shadow: 0px 3px 10px #0000000A !important;
    p{
      margin: 0 0 8px 0;
      padding: 0;
    }

    .post-status{
      height: 8px;
      margin-bottom: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  
    .draft{
      background-color: #C6C6C6;
  
    }
    .published{
      background-color: #24BC9C;
      color: #FFF;
    }
    
    .devo-img-container {
      & { height: 168px; width: 100%; max-height: 168px; max-width: 100%; margin-bottom: 16px;}
      .devotional-img {border-radius: 4px; object-fit: cover; height: 100%; width: 100%; }
    }
    .placeholder-img {
      & { border-radius: 4px; padding: 10px 0; max-height: 165px; min-height: 165px;  border-radius: 10px; border: 2px dashed #656565; display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; }
      img { border-radius: 4px; object-fit: contain; height: 125px; max-width: 70%; width: 70%; }
      .placeholder-text { height: 30%; text-align: center; width: 90%; }
      .episode-number {
        & { position: absolute; top: 30px; left: 40px; }
      }
      .episode-upload-thumbnail-btn {
        & { position: absolute; bottom: 165px; right: 40px; }
      }
    }

    .devo-title{
      
      min-width: 100%;
      .engaments-row{ display: flex; justify-content: space-between; }
      .editable-row{
        font-size: 14px; display: flex; justify-content: space-between;
        .status-row{align-items: center; display: flex; gap: 10px;
          .author-tags{
            .author-tag{ cursor: pointer; padding: 10px 20px; border-radius: 24px;}
          }
        }
      }
      .episode-upload-btn {
        & { background: #1359E4; color: #FFF; width: 100%; align-self: flex-end;  margin-top: 1rem; }
      }

     .devo-title-text { margin-top: 1rem; font-weight: 600; }
     .devo-title-date {
      font-size: 14px; font-weight: bold;
        span{ font-weight: normal;}
      } 

      .episodes-row {
        & { display: flex; align-items: center; justify-content: space-between; }
        .episode-list {
          & { display: flex; align-items: center; position: relative; }
          .episode-template {
            & { width: 180px; display: flex; align-items: center; position: relative; }
            .episode-thumb {
              & { display: flex; align-items: center; justify-content: center; position: absolute; height: 32px; width: 32px; background: #E9EAEE; border-radius: 5px; cursor: pointer; box-shadow: 0px 2px 5px #00000029; }
            }
            .episode-thumb-1 {
              & { left: 0px; }
            }
            .episode-thumb-2 {
              & { left: 15px; }
            }
            .episode-thumb-3 {
              & { left: 30px; }
            }
            .episode-thumb-4 {
              & { left: 45px; }
            }
            .episode-thumb-add-btn {
              & { left: 60px; }
            }
          }
          .episode-count {
            & { position: absolute; left: 100px; font-weight: 600; }
          }
        }
      }

      .people-enrolled-row {
        & { display: flex; min-height: 85px; align-items: center; justify-content: space-between; margin-bottom: 1rem; }
        .people-list {
          & { display: flex; align-items: center; position: relative; }
          .people-template {
            & { width: 150px; display: flex; align-items: center; position: relative; }
            .people-thumb {
              & { display: flex; align-items: center; justify-content: center; position: absolute; height: 32px; width: 32px; background: #E9EAEE; border-radius: 100%; cursor: pointer; box-shadow: 0px 2px 5px #00000029; }
            }
            .people-thumb-1 {
              & { left: 0px; }
            }
            .people-thumb-2 {
              & { left: 20px; }
            }
            .people-thumb-3 {
              & { left: 40px; }
            }
            .people-thumb-4 {
              & { left: 60px; }
            }
            .people-thumb-add-btn {
              & { left: 80px; }
            }
          }
          .people-count {
            & { position: absolute; left: 7px; top: 20px; font-weight: 600; }
          }
        }
      }
      .small-title {
        & { font-weight: 600; margin-top: 1rem; }
      }
      .course-btn {
        & { text-transform: capitalize; border: 3px solid #1359E4; color: #1359E4; }
      }
      .supporting-files-btn {
        & { text-transform: capitalize; border: 3px solid #1359E4; background: #1359E4; color: #FFF; }
      }
      .supporting-files-episode-btn {
        & { margin: 1rem 0; }
      }

     .devo-title-comment {
      font-size: 14px; font-weight: bold; 
        span{ font-weight: normal; }
        .comment-count { font-size: 14px; font-weight: bold;  padding: 0 .5rem; border: 1px solid #Aaa; border-radius: 5px; cursor: pointer; }
      } 
      .tags-row{ margin: 16px 0px 16px 0px; display: flex; flex-wrap: wrap; gap: 15px;


        .number-icon{ font-size: 10px; background-color: #1359E4; color: white; border-radius: 50%; display: flex; padding: 1px 5px;
          align-items: center; justify-content: center;  width: 20px; height: 20px; position: absolute; right: -12px; top: -12px;
        }
        .author-tag {
          & {position: relative; font-size: 14px;color: #000000; border-radius: 5px;
             height: 24px; background-color: transparent; padding: 8px;  display: flex; align-items: center;
             justify-content: center; gap: 5px;
             font-weight: 600;
              // border: 1px solid #AAA;
               cursor: pointer; }
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
        }
        .topic-tag {
          & {  position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
            padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; }
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
          
        }
        .keyword-tag {
          & {position: relative; font-size: 14px;color:#9B9B9B; height: 24px; background-color: #E9EAEE; 
            padding: 8px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; }
          .remove-author { height: 16px; width: 16px; cursor: pointer; }
          .add-author { height: 18px; width: 18px; cursor: pointer; }
        }  
      }
    }
  }
}

.episodes-container {
  & {  }
  .blog-card {
    & { margin-top: 1rem; }
  }
}


// modal
.post-comments {
  & {  width: 810px; height: 613px; display: grid; grid-template-rows: 50px 1fr 50px; }
  .comments-header {
    & { display: flex; align-items: center; justify-content: space-between; }
    .close { cursor: pointer; }
  }

  .comments {
    & { overflow-y: scroll; position: relative; }
    .no-comments {
      & { text-align: center; width: 51%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
    }
    .comment {
      & { display: flex; padding: 16px 0; border-bottom: 1px solid #e1e1e1; }
      &:last-child { border-bottom: none; }

      .comment-avatar {
        & { height: 40px; width: 40px; border-radius: 100%; }
        img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
        span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
      }
      .comment-details {
        & { padding: 0 1rem; width: calc(100% - 100px); }
        .username { font-family: $mainfont; font-size: 14px; font-weight: 700; color: #000; }
        .comment-text { font-family: $mainfont; font-size: 16px; color: #000; }
        .comment-actions {
          & { display: flex; align-items: center; justify-content: flex-start; gap: 1rem; }

          .comment-reply-btn { 
            & { height: 24px; width: 40px; background: #eee; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $mainfont; font-size: 12px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; transition: background 250ms ease-in-out, color 250ms ease-in-out; }
            &:hover { background: #CD4A4A; color: #FFF; }
          }
          .delete-comment-container { 
            & { height: 24px; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $mainfont; font-size: 12px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; }
            .delete-comment { 
              & { color: #aaa; transition: color 250ms ease; }
              &:hover { color: #CD4A4A; }
            }
          }
        }
        .comment-replies {
          & { width: calc(100% + 84px); }
          .reply-comment {
            & { display: flex; padding: 16px 0; border-bottom: 1px solid #e1e1e1; }
            &:last-child { border-bottom: none; }
            .reply-comment-avatar {
              & { height: 35px; width: 35px; border-radius: 100%; }
              img { height: 35px; width: 35px; border-radius: 100%; object-fit: cover; }
              span { height: 35px; width: 35px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
            }
            .reply-comment-details {
              & { padding: 0 1rem; width: calc(100% - 100px); }
              .username { font-family: $mainfont; font-size: 14px; font-weight: 700; color: #000; }
              .reply-comment-text { font-family: $mainfont; font-size: 16px; color: #000; }
              .comment-reply-btn { 
                & { height: 25px; width: 40px; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $mainfont; font-size: 12px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; transition: background 250ms ease-in-out; }
                &:hover { background: #00000020; }
              }
            }
            .reply-comment-approve-actions {
              & { margin-left: auto; height: 40px; width: 100px; display: flex; align-items: center; justify-content: space-between; }
              .approve-icon {
                & { cursor: pointer; border: 2px solid #000; border-radius: 100%; height: 22px; width: 22px; margin-right: 0.1rem; padding: 0.1rem; }
              }
              .approved { color: #6ab04c; border: 2px solid #6ab04c;  }
              .not-approved { color: #ff3a3a;  border: 2px solid #ff3a3a;  }
            }
          }
        }
        .show-more-comment-btn { 
          & { height: 25px; width: 200px; margin: 5px 0; display: flex; align-items: center; justify-content: center; font-family: $mainfont; font-size: 13px; font-weight: 700; color: #9b9b9b; border-radius: 5px; cursor: pointer; transition: background 250ms ease-in-out; }
          &:hover { background: #00000020; }
        }
      }
      .comment-approve-actions {
        & { margin-left: auto; height: 40px; width: 100px; display: flex; align-items: center; justify-content: space-between; }
        .approve-icon {
          & { cursor: pointer;  border: 2px solid #000; border-radius: 100%; height: 22px; width: 22px; margin-right: 0.1rem; padding: 0.1rem; }
        }
        .approved { color: #6ab04c; border: 2px solid #6ab04c;  }
        .not-approved { color: #ff3a3a; border: 2px solid #ff3a3a;  }
      }
    }
  }

  .add-comment {
    & { position: relative; display: flex; align-items: center; justify-content: center; gap: 8px; }
    .user-avatar {
      & { height: 40px; width: 40px; border-radius: 100%; }
      img { height: 40px; width: 40px; border-radius: 100%; object-fit: cover; }
      span { height: 40px; width: 40px; border-radius: 100%; display: flex; align-items: center; justify-content: center; }
    }
    .comment-input {
      & { width: calc(100% - 40px); height: 40px; padding-left: 10px; background: #FFFFFF; border: 1px solid #ccc; border-radius: 5px; }
      &:focus { outline: none; border: 1px solid #4a9fff; }
    }
    .post-btn {
      & { width: 95px; height: 40px; text-transform: capitalize; background: #CD4A4A; color: #FFF; border-radius: 5px; }
    }
    .reply-user-details {
      & { position: absolute; width: 100%; background: #CD4A4A; top: -40px; color: #fff; height: 40px; border-radius: 5px; display: flex; justify-content: space-between; align-items: center; padding: 0 1rem;}
      .close-reply { cursor: pointer; }
    }
  }
}

//Users Page 

.user-table-container{

  width: 100%;
  background-color: #FFFFFF;
  border-collapse: collapse;
  border-width: 0px;
  border-color: #7EA8F8;
  border-style: solid;
  color: #000000;
  .users-table{
    .table-head{

    }

    .table-body{

      .table-row{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 10px 3px 10px #0000000A;
        border-radius: 8px;
        margin-bottom: 1rem;
        
        .TableCell{
          font-weight: bold;
        color: black;
        }

        .permission-button{
         
          
          overflow: visible;

          button{
            position: relative;
            background-color: #E9EAEE;
            box-shadow: none;
            color: #9B9B9B;

            .number{
              position: absolute;
              top: -10px;
              display: flex;
              right: -10px; 
              background-color: #1359E4;
              color: white;
              height: 20px;
              width: 20px;
              align-items: center;
              justify-content: center;
              font-weight: normal;
             
              border-radius: 50%;
              font-size: 10px;
            }
          }
          
          
        }
        .popup-div{
          display: flex;
          align-items: end;
          justify-content: flex-end;
          width: 100%;

          .action-popup{
            border-radius: 5px;
            display: flex;
            gap: 15px;
            align-items: center;
            border: 1px solid gray;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        
        

        .table-cell{
          display: flex;
          align-items: center !important;
          gap:16px;
          height: 100%;
          vertical-align: middle;

          .avatar{
            background-color: #1359E4;
          }
          img{
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }
          input{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #9B9B9B;
            border-radius: 2px;
            height: 16px;
            width: 16px;
          }
        }

        .inactive{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 16px;
          color: white;
         
          
          padding: 5px 0 5px 0px;
          background-color: #C6C6C6;
        }
        .active{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 16px;
          color: white;
         
          
          padding: 5px 0 5px 0px;
          background-color: #1ABC9C;
        }
        .blocked{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 16px;
          color: white;
         
          
          padding: 5px 0 5px 0px;
          background-color: #E14949;
        }
      }
    }

  }
  

 


 
}
.user-container{
  width: 100%;
  height: 100vh;
  padding: 1rem;
  overflow-y: auto;
  background-color: #F6F7FA;
  .user-heading{
    display: flex;
    gap: 10px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-filters{
     
      display: flex;
      align-items: center;
      gap: 10px;
      height: 40px;
      TextField{
        height: 100% !important;
      }
      button{
        background-color: #1359E4;
        color: white;
      }
      .filter-popup{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap:5px;
        button{
          width: 100%;
          background-color: #1359E4;
          color: white;
        }
      }
      
    }
    
  }
}

.MuiPaper-root.MuiTableContainer-root {
  height: 100%;
  background-color: transparent;
}

.tags-table {
  height: 100% !important;
}
.topics-table {
  height: 100% !important;
}
.devotional-table {
  height: 100% !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important; 
}
.MuiTableCell-root{border-bottom: none !important; margin-bottom: 10px !important;}

.user-table-container .users-table .table-body .table-row {
 background: white !important; 
  box-shadow: 10px 3px 10px rgba(0, 0, 0, 0.0392156863);
  border-radius: 8px;
  margin-bottom: 1rem !important;
  padding: 1rem;
}

// .table-row:first-child td:first-child { border-top-left-radius: 10px; }
// .table-row:first-child td:last-child { border-top-right-radius: 10px; }

// .table-row:last-child td:first-child { border-bottom-left-radius: 10px; }
// tr:last-child td:last-child { border-bottom-right-radius: 10px; }


table {
border-collapse: separate !important;
  border-spacing:  0 1rem !important;
}


.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.MuiSnackbar-root { background-color: red !important; min-width: 300px; bottom: 100px !important; }


//devotional page

.data-table{

  .table-body{

    .table-row{

      
      .action-buttons-cell{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .booktitle{
        
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        .book-name-row{
          width: fit-content;
          span{
            font-size: 16px;
          }
          display: flex;
          gap: 20px;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 4px;
        }
        .blog-title-row{
          margin-bottom: 8px;
          display: flex;
          justify-content: flex-start;
          .blog-title{
            font-size: 16px;
            font-weight: normal;
            
          }
        }
        .book-div{ 
          display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
          .bookname{
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .filled{
            position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
      padding: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; 
          }
        }
      }

      .book-affiliate {
        .text-div{
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          position: relative;

          textarea { display: block; resize: none; margin-right: 0; border: 1px solid #C6C6C6; border-radius: 10px; padding: 8px; width: 100%;}
        
          
          div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
             
     .save-changes-affiliate-btn {
              & { background-color: #1359E4; color: white; margin-top: 1rem; font-weight: normal; text-transform: none;}
            }
          }
        }
       

       
      }

      .book-img {
        

        .background{
          background-color: grey;
          border-radius: 10px;
          margin: 16px;
          position: relative;
          padding: 16px;
          height: 140px;
          width: 140px;
          align-items: center;
          justify-content: center;
          display: flex;
          .bg-image{
            position: absolute;
            z-index: 0;
          }
          .edit-bg{
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 1;
            
  
          }
          .image{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          }
        }

        
      }

      .TableCell{
        display: table-cell !important;
       align-items: start;
       .edit-icons{
        height: 100%;
        .devo-edit-btn{
          cursor: pointer;
        }
        
       }
        
        .devo-title-text { font-weight: 600; padding-left: 8px;}
        .devo-caption{font-weight: normal; padding-left: 8px; font-size: 12px; color: #9B9B9B;}
        .author-tag{
          position: relative; font-size: 14px;color: #000000; border-radius: 5px;
             height: 24px; background-color: transparent; padding: 8px;  display: flex; align-items: center;
             justify-content: left; gap: 5px;
             font-weight: 600;
              // border: 1px solid #AAA;
               cursor: pointer; 

        }
        .topic-tag{
              
          position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
      padding: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; 
         }
        .author-tags{
          position: relative; font-size: 14px;color: #000000; border-radius: 5px;
             height: 24px; background-color: transparent; padding: 8px;  display: flex; align-items: center;
              gap: 5px;
             font-weight: 600;
              // border: 1px solid #AAA;
               cursor: pointer; 
               .topic-tag{
              
                position: relative; font-size: 14px;color:#9B9B9B;height: 24px; background-color: #E9EAEE; 
            padding: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: center; gap: 5px; cursor: pointer; 
               }

          .number-icon{ font-size: 10px; background-color: #1359E4; color: white; border-radius: 50%; display: flex; padding: 1px 5px;
            align-items: center; justify-content: center;  width: 20px; height: 20px; position: absolute; right: -12px; top: -12px;
          }
          .published{
            padding: 12px 16px 12px 16px;
            border-radius: 24px;
            font-size: 14px !important;
            font-weight: normal;
            color: white;
          }
          .draft{
            padding: 12px 16px 12px 16px;
            border-radius: 24px;
            font-size: 14px !important;
            font-weight: normal;
            color: white;
          }
        }
      }
    }
  }
}

.load-more-btn-container {
  & { width: 100%; display: flex; align-items: center; justify-content: center; height:50px; }
  .load-more-btn { 
    background: #1359E4; color: #fff;
    &:hover { background: #1e67f9; }
  }
}

//Episdoe Page

.epidsode-container{
  width: 100%; height: 100vh;  overflow-y: auto;
  // .flex-table{
  //   margin: 8px 1.5rem;
  // }
}

.course-episode-header{
  height: 80px;
  padding: 8px 24px;
  align-items: center;
  justify-content: space-between;
  background-color: #1359E4;
  display: flex;
  gap: 20px;
  .image-title{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  h1{
    font-size: 24px;
    color: #FFFFFF;

  }
  img{
    height: 48px;
    width: 48px;
    border-radius: 4px;
  }
}

.episode-nav{ 
  & { height: 40px; margin: 8px 1.5rem; display: flex; gap: 20px; color: #000000; font-size: 20px; }
  div{  cursor: pointer; }
}
.line{ margin-top: 8px; border: 2px solid #1359E4; border-radius: 5px; }

.edit-buttons{ display: flex; gap: 10px; }

.status-row{align-items: center; display: flex; gap: 10px;
  .author-tags{
    .author-tag{ cursor: pointer; padding: 10px 20px; border-radius: 24px;}
  }
}

.close-button{
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.image-container{
  height: 168px; width: 100%; max-height: 168px; max-width: 100%; margin-bottom: 16px; position: relative;
  .devo-img-container {
 
    .devotional-img {border-radius: 4px; object-fit: cover; height: 100%; width: 100%; }
    
  }
  .switch{
    position: absolute;
    top: 0;
    left: 8px;
    color: white;
}


  
}


.btn-primary {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #1359E4; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
  &:disabled {  background: #afafaf !important; }
} 

.btn-primary-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; border: 2px solid #1359E4; color: #1359E4; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 
