.data-table {

    .background {
      & { position: relative; height: 150px; width: 150px; background: #f1f1f1; border-radius: 10px; }
      .image {
        & { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }
      }
      &>img {
        & { height: 100%; width: 100%; object-fit: cover; }
      }
      img.edit-bg {
        & { height: 16px; width: 16px; position: absolute; right: 10px; top: 10px; z-index: 1; }
      }
    }
    .book-name-row {
      & { cursor: pointer; margin-bottom: 1rem; font-size: 18px; display: flex; align-items: center; justify-content: space-between; }
    }
    .blog-title-row {
      & { margin-bottom: 1rem; color: #969696; }
    }
  }
  