.overview-main-parent {
    & { height: 100vh; overflow-x: scroll; padding: 1rem; margin-bottom: 10rem; }

    // overview heading

    .overview-heading { color: black; }

    // total data panel

    .total-data-panel {
      h3 { margin: 0; }
      h1 { margin: 0; }
      & { display: flex; align-items: center; justify-content: space-between; }
      .total-data-panel-child {
        & { width: 245px; color: black; background: white; border-radius: 8px; }
        .total-data-inner-child {
          & { text-align: center; padding: 15px 0; display: flex; flex-direction: column; align-items: center; justify-content: space-around; }
          .total-no{display: flex; flex-direction: column; justify-content: center; align-items: center;}
          .heading { font-size: 1rem; }
          .total-data-lg-number { font-size: 2.5rem; color: black; }
          .total-data-middle-section { 
                &{ height: 100px; margin: 26px 0; }
           }
          .weekly-change { font-size: .8rem; }
        }
      }
      .pdf-and-email-section {
        & { display: flex; justify-content: space-around; width: 75%; margin: 0 auto; }
        .divide-line { height: 100%; width: 2px; background: #C6C6C6; }
        .pdf-and-email-icons {
          & { font-size: 2rem; display: flex; flex-direction: column; gap: 2px; }
          .pdf-email-text { margin: 0; font-size: .8rem; font-weight: 800; }
        }
      }
    }

    // Continent Map
    .continent-map{
        &{ display: flex; flex-direction: column; align-items: center; background: white; border-radius: 10px; margin: 20px 0; }

        .continent-map-heading{
            &{ margin: 25px 0; }
        }

       .map-heading{
        &{ text-align: center; font-size: 1.3rem; color: black; }
       } 
       
       .no-of-accounts-by-continents-container{
        &{ display: flex; justify-content: space-around; width: 100%; color: black; margin: 20px 0; }
            
            .continent-name{
                &{margin: 0; font-size: 1rem; }
            }

            .continent-color{
                &{ width: 13px; height: 13px; border-radius: 50%; }
            }

            .continent-color-and-name-container{
                &{ display: flex; align-items: center; gap: 10px; }
            }

            .Asia-continent-color { background-color: #2275B9; }
            .North-America-continent-color { background-color: #BB4468; }
            .Europe-continent-color { background-color: #CD4A4A; }
            .South-America-continent-color { background-color: #F2642C; }
            .Australia-continent-color { background-color: #F58826; }
            .Africa-continent-color { background-color: #844094; }
            .Antartica-continent-color { background-color: #1BA0A2; }
       }
    }

    .highcharts-credits{display: none !important;}

    // Pie charts section
    .pie-charts-section{
      &{ display: flex; justify-content: space-between; }
      .iosAndroidContainer-heading{ font-weight: bold; color: black; text-align: center; }
      .desktopAndMobileData-heading{ font-weight: bold; color: black; text-align: center; }
      .users-count{ 
        &{ display: flex; width: 50%; margin: auto; justify-content: space-between; }
        h4{
          &{ margin: 0; color: black; }
        }
      }
      .users-icon{
        &{ display: flex; align-items: center; gap: 8px; }
        .users-indicator{
          &{ width: 12px; height: 12px; border-radius: 50%; }
        }
      }
      .no-of-users{ color: black; margin: 0; text-align: center; }
      .desktopAndMobileData{
        &{ background: white; padding: 1rem; width: 49%; border-radius: 10px; }
        .desktop-users-count{
          .users-indicator{
            &{ background:#844094; }
          }
        }
        .mobile-users-count{
          .users-indicator{
            &{ background:#BB4468; }
          }
        }
      }
      .iosAndroidData{
        &{ background: white; padding: 1rem; width: 49%; border-radius: 10px; }
        .ios-users-count{
          .users-indicator{
            &{ background:#2275B9; }
          }
        }
        .android-users-count{
          .users-indicator{
            &{ background:#F2642C; }
          }
        }
      }
    }

    /* Most popular contents */
    .most-popular-section{
      &{ display: flex; gap: 20px; }
      .content-box{
        &{ background-color: white; padding: 15px; border-radius: 10px; margin: 20px 0; }
        h4{
          &{ text-align: center; margin: 0; color: black; }
        }
      }
      img{ width: 100%; margin: 10px 0; }
    }

  }
  